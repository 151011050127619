import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import IgGrid from "../components/Modules/Instragram/ig-grid";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { facebookLink, instagramLink } from "../common/helpers/config";
import { SiFacebook } from "@react-icons/all-files/si/SiFacebook";
import { StaticImage } from "gatsby-plugin-image";
import Link from "../components/UI-Elements/Navigation/Link";
import Card from "../components/UI-Elements/Card";
import Title from "@components/UI-Elements/Typography/Title";

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={['instagram', 'links']} title="Instagram" />

      <section className="mt-10 flex flex-col md:flex-row">
        <div className="md:w-2/3 mt-5 text-center">
          <Title>Mehr von uns</Title>

          <Card>
            <p>Du findest weitere Inhalte von uns unseren Social Media Profile auf Instagram oder Facebook. Folge uns dort, um die neusten Beiträge nicht zu verpassen.</p>
            {/* <p>Folge uns auf Instagram oder Facebook, um die neusten Beiträge nicht zu verpassen.</p> */}
            <div className="flex justify-center p-3">
              <Link href={instagramLink} external className="text-3xl p-1">
                <SiInstagram />
              </Link>
              <Link href={facebookLink} external className="text-3xl p-1">
                <SiFacebook />
              </Link>
            </div>
            <Link href="/instagram" external={false}>Mehr erfahren</Link>
          </Card>
        </div>

        <div className="md:w-1/3 px-10">
          <StaticImage
            src="../images/landingpage/instagram-iphone-light.png"
            alt="Einfach Crypto auf Instagram"
            className="block mx-auto"
          />
        </div>
      </section>

      <section>
        <div className="text-center p-5">
          <h2 className="inline-block text-2xl font-bold ">Die aktuellsten Beiträge</h2>
        </div>
        <IgGrid />
      </section>
    </Layout>
  );
};

export default IndexPage;
