import React from "react";

const IgGrid = () => {
  return (
    <div className="w-full h-[850px]">
      <script src="https://snapwidget.com/js/snapwidget.js"></script>
      <iframe
        src="https://snapwidget.com/embed/983150"
        className="snapwidget-widget"
        // allowTransparency
        frameBorder="0"
        scrolling="no"
        style={{ border: "none", overflow: "hidden", width: "100%", height: "100%" }}
      ></iframe>
    </div>
  );
};
export default IgGrid;
